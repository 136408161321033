/* You can add global styles to this file, and also import other style files */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
table {
  width: 100%;
}

table > thead {
  z-index: 1;
  position: relative;
}

th {
  position: sticky;
  top: 0;
  background-color: white;
}

mat-table {
  overflow: auto;
}

.cell-highlight {
  background-color: slategray;
  position: sticky;
  top: 56px;
  .mat-cell {
    color: white;
    font-weight: 500;
  }
}

.search-input {
  font-size: 14px !important;
  text-transform: uppercase !important;
  line-height: 1.5 !important;
}

.btn-remove {
  font-size: 12px !important;
}

.readonly {
  pointer-events: none;
  user-select: none;

  .mat-select-panel {
    color: #888888;
  }
}

.no-select {
  user-select: none;
  cursor: default;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=time]::after {
  display: none;
}

.logo-image {
  width: 200px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}

.icon-md {
  font-size: 40px;
  height: 40px !important;
  width: 40px !important;
  padding: 5px;
}

.icon-lg {
  font-size: 60px;
  height: 60px !important;
  width: 60px !important;
}

.emoji {
  font-size: 30px;
}

.link-preview {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 10px;

  iframe {
    width: 100%;
    border-radius: 4px;
    border: none;
  }

  a {
    text-overflow: ellipsis;
    width: 100%;
  }
}

.translucent {
  opacity: 0.5;
}

.container {
  top: 64px !important;
}

@media (max-width: 599px) {
  .container {
    top: 56px !important;
  }
}

tr.mat-footer-row {
  font-weight: bold;
}

.container-content {
  padding: 30px;
}

.menu-spacer {
  flex: 1 1 auto;
}

.sidenav-container {
  flex: 1;
}

.full-container {
  width: 98%;
  padding: 2px;
  flex: 1;
}

.half-container {
  width: 49%;
  padding: 2px;
  flex: 1;
}

.rounded-field {
  border-radius: 50%;
}

.bottom-sheet-large {
  width: 800px;
  overflow: auto;
}

.dialog-large {
  width: 500px;
  overflow: auto;
}

.dialog-extra-large {
  width: 900px;
  overflow: auto;
}

.mat-radio-button {
  margin-left: 8px;
}
/* default .loading styles, .loading should be invisible, opacity: 0, z-index: -1 */
.loading {
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  position: fixed;
  width: 100%;
  top: 0;
  left: 10;
  z-index: -1;
}

.download-progress {
  color: white;
  padding: 50px 10px;
  font-weight: bold;
  font-size: 16px;
}

.typing {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

.typing-effect {
  animation: typing 2s steps(3) infinite;
  white-space: nowrap;
  overflow: hidden;
}

/* .loading screen is visible when app is not bootstrapped yet, my-app is empty */
app-root:empty + .loading {
  opacity: 1;
  z-index: 100;
}

.main-div {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-card {
  max-width: 400px;
}

.large-card {
  max-width: 900px;
}

.scrollable-table-container {
  max-height: 500px;
  position: relative;
  overflow: auto;
}

.scrollable-list-container {
  max-height: 300px;
  overflow: auto;
  padding-top: 0px !important;
}

.default-card-header-image {
  background-image: url("/assets/images/icon.png");
  background-size: cover;
  filter: invert(90%);
}

.strikethrough {
  text-decoration: line-through;
}

.mat-chip {
  font-size: 12px;
}

.bg-label {
  color: white;
  padding: 5px;
  border-radius: 4px;
  text-transform: uppercase;
}

.button-row {
  display: table-cell;
}

mat-paginator {
  background-color: transparent !important;

  .mat-form-field-appearance-outline .mat-form-field-outline-start,
  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border: none;
  }

  .mat-paginator-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
}

mat-slide-toggle {
  .mat-slide-toggle-thumb {
    width: 14px;
    height: 14px;
    margin: 1px;
  }

  .mat-slide-toggle-thumb-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 1px 2px 1px;
  }

  .mat-slide-toggle-bar {
    width: 32px;
    padding: 2px 1px 2px 1px;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: #e9e9e9;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #4c4c4d46;
  box-shadow: inset 0 0 6px #2c2c2c69;
  border-radius: 4px;
}

.link {
  color: #551a8b;
}

.success {
  background-color: #2ecc71;
} /* Green */
.info {
  background-color: #3498db;
} /* Blue */
.warning {
  background-color: #f5b041;
} /* Orange */
.danger {
  background-color: #e74c3c;
} /* Red */
.other {
  background-color: #d6dbdf;
  color: #5d6d7e;
} /* Gray */
.other-dark {
  background-color: #4c4c4d;
} /* Gray Dark */

.success-text {
  color: #2ecc71;
} /* Green */
.info-text {
  color: #3498db;
} /* Blue */
.warning-text {
  color: #f5b041;
} /* Orange */
.danger-text {
  color: #e74c3c;
} /* Red */
.other-text {
  color: #5d6d7e;
} /* Gray */
.other-dark-text {
  color: #4c4c4d;
} /* Gray Dark */

/* Animations */
.animate-slideInDown {
  animation: slideInDown 1s;
}

.animate-tada {
  animation: tada 1s;
}

.animate-shake {
  animation: shake 1s infinite;
}

.mat-row {
  animation: rows-load 1s;
}

.highlight {
  animation: highlight-element 1s;
}

@keyframes slideInDown {
  0% {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-2deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate(-2deg);
  }
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(2deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(2deg);
  }
  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-2deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate(-2deg);
  }
  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

@keyframes rows-load {
  0% {
    transform: translateY(-100%) rotateX(200deg);
    background-color: lightgrey;
    opacity: 0;
  }
  to {
    transform: translateY(0) rotateX(360deg);
    opacity: 1;
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

@keyframes uncheck-check {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  30% {
    width: 16px;
  }
  70% {
    width: 16px;
  }
  to {
    width: 0;
  }
}

@keyframes highlight-element {
  0% {
    border: 2px solid #7b204a;
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  75% {
    border: 2px solid #7b204a;
  }
  to {
    transform: scale(1);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
